// -----------------------------------------------------------------------------
// Styles typographiques de base
// -----------------------------------------------------------------------------

// Titres
.title {
  font-family: oswald, sans-serif;
  text-transform: uppercase;
}

// Exergue
.lead {
  padding: $spacer * 2;
  margin: $spacer * 2 0;
  color: $gray-700;
  border: 1px solid $primary;
}
