// -----------------------------------------------------------------------------
// card component, extended from bootstrap
// -----------------------------------------------------------------------------

.card {
  margin: $spacer 0;
  .card-header {
    font-weight: $font-weight-bold;
  }
  .form-group {
    margin-bottom: $spacer;
  }
  &.tile {
    width: calc(300px + #{$spacer} * 2);
    min-height: 200px;
  }
}
