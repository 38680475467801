// -----------------------------------------------------------------------------
// Application-wide variables
// -----------------------------------------------------------------------------

/// Path to fonts and images folders, relative to css/app.css.
/// @type String
$font-path: "../fonts";
$img-path: "../img";

// -----------------------------------------------------------------------------
// Bootstrap's configuration for the application
// -----------------------------------------------------------------------------
// see: ../../node_modules/bootstrap/scss/_variables.scss

// Color system

// You can generate a color scheme with:
// https://palx.jxnblk.com/

$blue: #1c4493; // charte
$indigo: #a199cf;
$purple: #bf87f6;
$pink: #e31b68; // charte
$red: #dc3545;
$orange: #ef8a25; // charte
$yellow: #ddb816;
$green: #0fa24f; // charte
$teal: #1cb769;
$cyan: #1bb1b1;

$gray-800: #3c4b51; // charte
$dark: $gray-800;

$primary: $orange;
$primary-dark: darken($primary, 20%);
$secondary: $blue;

$breadcrumb-bg: $primary;
$breadcrumb-border-radius: .25rem;
$breadcrumb-divider: quote(">");
$breadcrumb-divider-color: $primary-dark;

// Options

$enable-validation-icons: false;
