// -----------------------------------------------------------------------------
// Pied de page de la plateforme
// -----------------------------------------------------------------------------

.footer {
  padding: 4rem 0;
  margin-top: $spacer;
  color: $white;
  background-color: $gray-800;
  a {
    color: $white;
  }
}

.footer-infos {
  .fa {
    margin-right: $spacer;
  }
}
